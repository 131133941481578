import Banner_img from '../../img/banner_wbg.png';
import { Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import Board from './Board';
import MeetTheTeam from './Us';

export default function Home({ handlePageChange }: { handlePageChange: (page: string) => void }) {
  return (
    <>
        <Box
          sx={{
            backgroundImage: `url(${Banner_img})`,
            height: "100vh",
            paddingX: { xs: "5%", sm: "10%" },
            display: "flex",
            alignItems: "center",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            justifyContent: { xs: "center", sm: "flex-end" },
            textAlign: { xs: "center", sm: "left" },
            scrollSnapAlign: "start",
          }}>

          <Box
            sx={{
              color: "white",
              maxWidth: { xs: "90%", sm: "60%", md: "40%" },
            }}>

            <Typography
              variant="h1"
              sx={{
                fontWeight: "bold",
                marginTop: { xs: "20%", sm: "35%", md: "45%" },
                marginBottom: "5%",
                fontSize: { xs: "2rem", sm: "3rem", lg: "4.25em" },
              }}>
              Get our latest news and updates
            </Typography>

            <Typography
              variant="h3"
              sx={{
                marginBottom: "5%",
                fontSize: { xs: "1.25rem", sm: "1.75rem", md: "2rem" },
              }}>
              Subscribe to our newsletter
            </Typography>

            {/* Subscribe */}
            <Button
              variant="outlined"
              sx={{
                color: "white",
                borderColor: "white",
                padding: { xs: "8px 16px", sm: "10px 20px" },
                "&:hover": {
                  borderColor: "white",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}>
              {/*Subscribe*/}
              Coming Soon
            </Button>
          </Box>
        </Box>

        <Board handlePageChange={handlePageChange} />

        <MeetTheTeam handlePageChange={handlePageChange} />

    </>
  );
}
