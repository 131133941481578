import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import boardImage from '../../img/board.png';
import bgImage from '../../img/board_background.jpg';

interface BoardProps {
  handlePageChange: (page: string) => void;
}

const Board: React.FC<BoardProps> = ({ handlePageChange }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${bgImage})`,
        height: "100vh",
        paddingX: { xs: "5%", sm: "10%" },
        display: "flex",
        alignItems: "center",             
        backgroundSize: "cover",
        backgroundPosition: "center", 
        position: "relative",       
        justifyContent: { xs: "center", sm: "flex-end" },
        textAlign: { xs: "center", sm: "left" },
        scrollSnapAlign: "start", // Snap each section at the start
      }}
    >
      {/* Text and Button */}
      <Box sx={{ 
        flex: 1, 
        color: "#fff", 
        maxWidth: "300px", 
        textAlign: "left" 
        }}>

        <Typography
          variant="h2" 
          sx={{ 
            fontWeight: "bold", 
            marginBottom: "5%",
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" }, 
            }}>              
          Introducing our next generational product
        </Typography>


        <Button
          variant="outlined"
          onClick={() => handlePageChange("Solutions")}
          sx={{
            color: "#fff",
            borderColor: "#fff",
            padding: "10px 20px",
            textTransform: "none",
            "&:hover": {
              borderColor: "#fff",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          Learn More
        </Button>
      </Box>

      {/* Product Image */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={boardImage}
          alt="Product board"
          style={{ width: "100%", maxWidth: "1000px", objectFit: "contain" }}
        />
      </Box>
    </Box>
  );
};

export default Board;
