import { Box, Grid } from '@mui/material';
import ProjectCard from './Card';
import bgImage from "../../img/background.jpg";
import board from '../../img/board.png';
import acisw from '../../img/acisw.jpg';
import { acisw_card, board_card } from '../../text';

interface ProjectsProps {
  handlePageChange: (page: string) => void;
}

export default function Projects({ handlePageChange }: ProjectsProps) {
  const projects = [
    {
      name: 'Payload Platform',
      description: board_card,
      image: board,
      page: 'PayloadPlatform',
    },
    {
      name: 'AciSoftware',
      description: acisw_card,
      image: acisw,
      page: 'AciSoftware',
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        paddingTop: "80px",
      }}
    >
      <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        {projects.map((project, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <ProjectCard
              name={project.name}
              description={project.description}
              image={project.image}
              onClick={() => handlePageChange(project.page)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
