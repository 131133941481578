import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import bgImage from "../../img/background.jpg";

interface FormData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const Contact: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [loading] = useState(false);
  const [formDisabled] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
      }}>
      
      <Box
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: "40px",
          borderRadius: "10px",
          maxWidth: "500px",
          width: "100%",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
        }}>
        
        {/* Form is out of operation message*/}
        <Typography
          sx={{ whiteSpace: 'pre-line', textAlign: "center", color: "red", marginBottom: "10px" }}>
          The contact form is currently out of operation.{"\n"}
          Please use the direct email option located at the bottom of the page.{"\n"}
          AciSpace apologizes for any inconvenience caused!
        </Typography>

        <Typography variant="h4" sx={{ textAlign: "center", marginBottom: "30px" }}>
          Contact Us
        </Typography>

        <form onSubmit={(e) => e.preventDefault()}>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
            disabled={formDisabled}
            required/>
          
          <TextField
            name="email"
            label="Email"
            variant="outlined"
            fullWidth
            value={formData.email}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
            disabled={formDisabled}
            required/>
          
          <TextField
            name="subject"
            label="Subject"
            variant="outlined"
            fullWidth
            value={formData.subject}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
            disabled={formDisabled}
            required/>
          
          <TextField
            name="message"
            label="Message"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={formData.message}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
            disabled={formDisabled}
            required/>
          
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={formDisabled}
            sx={{
              backgroundColor: "#007BFF",
              color: "#fff",
              padding: "10px 0",
              "&:hover": {
                backgroundColor: "#0056b3",
              },
            }}>
            Submit
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default Contact;
